<template>
	<!-- 视频播放 -->
	<div v-if="pageType==1">
		<div class="mbm_preview_video_item_videoBox" :style="{height:414+'px',}">
			<video class="mbm_play_video" :autoplay="autoplay" :src="videoUrl" :style="'opacity:'+opacity+';'"
				controlslist="nodownload" controls="controls" :height="414+'px'" ref="video">
				<!-- <meta name="referrer" content="never"> -->
			</video>
			<div v-if="playerImgShow" class="mbm_video_coverImg">
				<img :src="info.cover_path" style="max-height: 100%;max-width: 100%;" />
			</div>
			<div v-if="playerImgShow" class="mbm_preview_video_item_playerBox" @click="playerVideo()">
				<div class="mbm_video_playerBtn">
					<span class="mbm-iconfont mbm-bofang"></span>
				</div>
				<div class="mbm_video_time">{{formatSeconds(info.duration)}}</div>
			</div>
		</div>
	</div>
	<!-- 音频播放 -->
	<div v-if="pageType==2">
		<div class="mbm_audio_box">
			<div class="mbm_audio_name">{{info.filename}}</div>
			<div class="mbm_audio_playerBtn" @click="audioPlayPause">
				<img v-if="isPlay" class="mbm_playerBtn" src="/img/play.png" />
				<img v-if="!isPlay" class="mbm_playerBtn" src="/img/pause.png" />
				<!-- <span v-if="isPlay" class="mbm-iconfont mbm-bofang"></span>
				<span v-if="!isPlay" class="mbm-iconfont mbm-24gf-pause2"></span> -->
			</div>
			<div class="mbm_audio_size">
				{{info.filesize}}
			</div>
			<div class="mbm_progress_box">
				<a-slider strokeWidth="2" class="mbm_slider" :min="0" :max="Math.floor(info.duration) " v-model:value="currentTime"
					:disabled="disabled" @change="sliderChange" @afterChange="sliderAfterChange" @focus="touchSlider"
					@blur="sliderBlur" />
			</div>
			<div class="mbm_audio_timeBox">
				<span>{{realMusicTime}}</span>
				<span>{{totalMusicTime}}</span>
			</div>
		</div>


		<audio ref="music" :src="audioUrl" preload="auto">
			<!-- <meta name="referrer" content="never"> -->
		</audio>

	</div>
</template>

<script>
	import baseFunc from '../config/base_func.js';
	export default {
		name: 'player',
		components: {

		},
		data() {
			return {
				/* 视频 */
				videoUrl: '',
				autoplay: false,
				playerImgShow: true,
				/* 音频 */
				audioUrl: '',
				currentTime: 0,
				realMusicTime: "00:00",
				totalMusicTime: this.formatSeconds(this.info.duration),
				isPlay: true,
				disabled: false,
				opacity: 0,
			}
		},
		props: {
			pageType: Number,
			info: Object,
		},
		watch: {
			info: (newVal, oldVal) => {
				// console.log(newVal)
				this.videoUrl = '';
				this.autoplay = true;
				this.playerImgShow = true;
				this.mediaInit()
			},
		},
		created() {},
		mounted() {
			this.mediaInit()
		},
		methods: {
			mediaInit() {
				if (this.pageType == 1) {
					if (typeof this.info.cover != "undefined" && typeof this.info.cover.url != "undefined" && this.info
						.cover.url) {
						this.info.cover_path = baseFunc.getQiniusign(this.info.cover.url);
					} else if (this.info.cover_path) {
						this.info.cover_path = baseFunc.getQiniusign(this.info.cover_path, 0);
					} else {
						this.info.cover_path = baseFunc.getQiniusign(this.info.url, 0);
					}
				}
				if(this.info.party_path_g){
					this.videoUrl = baseFunc.getQiniusign(this.info.party_path_g);
				}else if(this.info.transcode_url){
					this.videoUrl = baseFunc.getQiniusign(this.info.transcode_url);
				}
				if(this.info.party_path_g){
					this.audioUrl = baseFunc.getQiniusign(this.info.party_path_g);
				}else if(this.info.transcode_url){
					this.audioUrl = baseFunc.getQiniusign(this.info.transcode_url);
				}
				if (this.pageType == 2) {
					this.watchMusicTime();
				}
			},
			//播放视频
			playerVideo() {
				this.$refs["video"].play();
				this.autoplay = true;
				this.playerImgShow = false;
				this.opacity = 1;
			},
			//秒=>时:分:秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

				let res = '';
				if (h !== '00') res += `${h}:`;
				res += `${m}:`;
				res += `${s}`;
				return res;
			},
			//音频播放暂停
			audioPlayPause() {
				// console.log(this.audioUrl)
				let that = this;
				if (this.audioUrl == '') {
					this.firstPlay();
				} else {
					if (this.music.paused) {
						this.music.play();
					} else {
						this.music.pause();
					}
				}

			},
			firstPlay() {
				let that = this;
				// console.log(this.info)
				
				if(this.info.party_path_g){
					this.audioUrl = baseFunc.getQiniusign(this.info.party_path_g);
				}else if(this.info.transcode_url){
					this.audioUrl = baseFunc.getQiniusign(this.info.transcode_url);
				}
				// console.log(this.audioUrl)
				setTimeout(function() {
					that.music.play();
				}, 200);
			},
			// 处理时间
			handlMusicTime() {
				//用秒数来显示当前播放进度
				let timeDisplay = Math.floor(this.music.currentTime); //获取实时时间
				//分钟
				let minute = parseInt(timeDisplay / 60);
				if (minute < 10) {
					minute = "0" + minute;
				}
				//秒
				let second = Math.round(timeDisplay % 60);
				if (second < 10) {
					second = "0" + second;
				}
				this.currentTime = timeDisplay;
				this.realMusicTime = minute + ":" + second;
			},
			// 处理点击进度条事件
			handClickBar(e) {
				const barTotalWidth = this.bar.offsetWidth; // bar 总宽度
				const rect = e.target.getBoundingClientRect(); // 元素右边距离页面边距的距离 返回上下左右
				let length = e.pageX - rect.left;
				this.music.currentTime = length / barTotalWidth * this.music.duration; // 计算播放时间 位置百分比*总时间
				this.$nextTick(() => {
					this.music.play();
					this.isPlay = true;
				})
			},
			//滑块值改变
			sliderChange(value) {

				this.music.currentTime = this.currentTime;
			},
			//滑块值改变后
			sliderAfterChange(value) {
				this.$nextTick(() => {
					if (this.audioUrl == '') {
						this.firstPlay();
					} else {
						this.music.play();
					}

				})
			},
			//点击滑块获取焦点
			touchSlider() {
				this.music.pause();
			},
			//滑块失去焦点
			sliderBlur() {

			},

			//使用事件监听方式捕捉事件
			watchMusicTime() {
				this.music = this.$refs.music;
				this.bar = this.$refs.bar;
				this.music.addEventListener(
					"timeupdate",
					() => {
						this.handlMusicTime();

					},
					false
				);
				//播放
				this.music.addEventListener("play", () => {
					this.isPlay = false;
				});
				//暂停
				this.music.addEventListener("pause", () => {
					this.isPlay = true;
				});
				// 播放完毕
				this.music.addEventListener("ended", () => {
					this.currentTime = 0;
					this.realMusicTime = "00:00";
					// this.totalMusicTime = "00:00";
					this.isPlay = true;
				});
				// 捕获音频文件已准备完毕
				// 当媒体文件可以播放的时候会触发oncanplay事件,也可以用oncanplay
				this.music.oncanplaythrough = () => {
					// let time = this.music.duration;
					// //分钟
					// let minutes = parseInt(time / 60);
					// if (minutes < 10) {
					// 	minutes = "0" + minutes;
					// }
					// //秒
					// let seconds = Math.round(time % 60);
					// if (seconds < 10) {
					// 	seconds = "0" + seconds;
					// }
					// this.totalMusicTime = minutes + ":" + seconds;
				};
			}
		},
	}
</script>

<style>
	.mbm_preview_video_item_videoBox {
		position: relative;
	}

	.mbm_play_video {
		width: 100%;
		height: 100%;
	}

	.mbm_video_coverImg {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: all .3s;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		z-index: 99;
	}

	.mbm_preview_video_item_playerBox {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.5);
		cursor: pointer;
		z-index: 99;
	}

	.mbm_video_playerBtn {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		background: #FFFFFF;
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%, -50%);

	}

	.mbm_video_playerBtn>span {
		font-size: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.mbm_video_time {
		position: absolute;
		right: 8px;
		bottom: 6px;
		color: #FFFFFF;
		font-size: 14px;
	}


	.mbm_audio_box {
		padding: 37px 34px;
		width: 100%;
		height: auto;
		text-align: center;
	}

	.mbm_audio_name {
		color: #333333;
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 24px;
		line-height: 1.2;
	}

	.mbm_audio_playerBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-bottom: 30px;
	}

	.mbm_playerBtn {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border-color: #167fe8;
		background-color: #167fe8;
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
	}

	.mbm_audio_playerBtn>span {
		font-size: 58px;
		top: 50%;
		left: 50%;
		color: #167FE8;
	}

	.mbm_audio_size {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.7);
		line-height: 1;
	}

	.mbm_progress_box {
		box-sizing: border-box;
		height: 100%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.mbm_progress_box>span {
		margin: 0 10px;
	}

	.mbm_slider {
		flex: 1;
		margin-top: 10px !important;
	}

	.mbm_slider .ant-slider-track {
		background-color: #167FE8 !important;
	}

	.mbm_slider .ant-slider-handle {
		background-color: #167FE8 !important;
	}

	.mbm_audio_timeBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.7);
	}
</style>
