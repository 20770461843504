<template>
	<div v-if="cardShow" class="fioi_info_card">
		<!-- 加载动画 -->
		<loading defaultClass="mbm_loading" :loadingShow="loadingShow" :type="4" title=""
			style="height:100%;position:absolute">
		</loading>
		<div class="fioi_info_top">
			<span class="fioi_info_top_title">组织信息</span>
			<a-button class="fioi_info_top_btn" type="primary" @click="openModel">完善组织信息</a-button>
		</div>
		<div class="fioi_info_subtitle">
			完善信息后，可以添加成员，参与组织协作，如查看内部二维码，填写指定表单，接收消息提醒等
		</div>

		<div class="fioi_info_content">
			<div class="fioi_info_content_picBox">
				
				<a-image class="fioi_info_content_pic" width="100%" height="100%" :src="organization.logo" fallback="/img/company.png"></a-image>
			</div>
			<div class="fioi_info_content_textBox">
				<div class="fioi_info_content_text_item">
					<span>组织名称：</span>{{organization.name?organization.name:'未完善'}}
				</div>
				<div class="fioi_info_content_text_item">
					<span>所在行业：</span>{{organization.industry?organization.industry:'未完善'}}
				</div>
				<div class="fioi_info_content_text_item">
					<span>员工规模：</span>{{organization.staffSize?organization.staffSize:'未完善'}}
				</div>
			</div>
		</div>
	</div>



	<a-modal class="ocm_createModal" :centered="true" v-model:visible="fioiModelShow" :title="formData.name==''?'填写组织信息':'修改组织信息'"
		:ok-text="formData.name==''?'确认':'修改'" cancel-text="取消" :width="800" @ok="onSubmit" :destroyOnClose="true">
		<div class="fioi_content_box">
			<!-- <div class="fioi_content_title">填写组织信息</div> -->
			<a-form ref="formRef" :model="formData" :rules="formDataRules">
				<a-form-item ref="logo" label="LOGO" name="logo">
					<div style="margin-left:30px;">
						<div v-if="imgSrc" class="fioi_preview_box">
							<span class="mbm-iconfont mbm-ashbin fioi_delete_icon" @click="removeFile()"></span>
							<a-image :src="imgSrc"></a-image>
						</div>
						<div class="fioi_upload_box" v-if="!imgSrc">
							<a-upload :file-list="[]" list-type="picture-card">
								<div>
									<span class="mbm-iconfont mbm-jia"></span>
									<div class="ant-upload-text">添加图片</div>
								</div>
							</a-upload>
							<input type="file" class="fioi_upload_btn" @change="logoChange" />
						</div>
					</div>
				</a-form-item>
				<a-form-item ref="name" label="组织名称" name="name">
					<a-input v-model:value="formData.name" placeholder="请输入组织名称"></a-input>
				</a-form-item>
				<a-form-item ref="industry" label="所在行业" name="industry">
					<a-dropdown :trigger="['click']">
						<!-- <a class="ant-dropdown-link" @click.prevent>
							Cascading menu
							<DownOutlined />
						</a> -->
						<a-select v-model:value="formData.industry" @click.prevent style="width: 100%"
							:options="industryOptions" :defaultOpen="false" :open="false">

						</a-select>
						<template #overlay>
							<a-menu style="max-height:300px;overflow:auto;width:60%;">
								<a-sub-menu v-for="(item,index) in menuOptions" :key="item.id" :title="item.name">
									<a-menu-item v-for="(citem,cindex) in item.children"
										@click="industryChange(citem.id)">{{citem.name}}</a-menu-item>
								</a-sub-menu>
							</a-menu>
						</template>
					</a-dropdown>

				</a-form-item>
				<a-form-item ref="staffSize" label="员工规模" name="staffSize">
					<a-select v-model:value="formData.staffSize" style="width: 100%" :options="staffSizeOptions"
						@change="staffSizeChange">
					</a-select>
				</a-form-item>


				<!-- <a-form-item>
					<div class="fioi_submint_btn_box">
						<a-button type="primary" class="fioi_submint_btn" @click="onSubmit">提交</a-button>
					</div>

				</a-form-item> -->
			</a-form>
		</div>
	</a-modal>
</template>

<script>
	import baseFunc from '/src/config/base_func.js';
	import {
		Empty,
		Modal,
		message,
	} from 'ant-design-vue';
	import loading from '/src/components/loading.vue';
	export default {
		name: 'fillInOrganizationInformation',
		components: {
			loading
		},
		emits: ['refash'],
		data() {
			return {
				loadingShow: true,
				fioiModelShow: false,
				formData: {
					logo: '',
					name: '',
					industry: 2,
					staffSize: 1,

				},
				organization: {
					logo: '',
					name: '',
					industry: '',
					staffSize: '',
				},
				menuOptions: [],
				industryOptions: [],
				staffSizeOptions: [],
				imgSrc: '',
				formDataRules: {
					name: [{
						required: true,
						message: '组织名称必填',
						trigger: 'blur'
					}],
					industry: [{
						required: true,
						message: '所在行业必填',
						trigger: 'change',
						type: 'number'
					}],
					staffSize: [{
						required: true,
						message: '员工规模必填',
						trigger: 'change',
						type: 'number'
					}],
				},

			}
		},
		props: {
			modelShow: {
				type: Boolean,
				default: false
			},
			cardShow: {
				type: Boolean,
				default: true
			}

		},
		watch: {
			modelShow: function(nVal, oVal) {
				this.fioiModelShow = nVal;
			},
		},
		created() {
			this.getModelData();
			this.fioiModelShow = this.modelShow;

		},
		mounted() {

		},
		methods: {
			// 获取权限列表
			getRules() {
				let that = this;
				that.$apiRequest.post("/department/initData", {}).then(res => {
					if (res.data.organization) {
						this.imgSrc = res.data.organization.logo;
						this.formData.logo = '';

						this.formData.name = res.data.organization.name;
						this.formData.industry = res.data.organization.industry_id;
						this.formData.staffSize = res.data.organization.staff_range_id;

						this.organization.logo = res.data.organization.logo;
						this.organization.name = res.data.organization.name;

						for (let i = 0; i < this.industryOptions.length; i++) {

							if (res.data.organization.industry_id == this.industryOptions[i].value) {
								this.organization.industry = this.industryOptions[i].label;
								break;
							}
						}
						for (let i = 0; i < this.staffSizeOptions.length; i++) {
							if (res.data.organization.staff_range_id == this.staffSizeOptions[i].value) {
								this.organization.staffSize = this.staffSizeOptions[i].label;
								break;
							}
						}
					}
					this.loadingShow = false;
				}).catch(err => {
				});
			},
			getModelData() {
				this.loadingShow = true;
				let that = this;
				that.$apiRequest.post('/department/orgIndustry', {}).then(res => {
					for (let i = 0; i < res.data.industries.length; i++) {
						let item = res.data.industries[i];
						for (let j = 0; j < res.data.industries[i].children.length; j++) {
							that.industryOptions.push({
								value: res.data.industries[i].children[j].id,
								label: res.data.industries[i].name + '-' + res.data.industries[i].children[
									j].name,
							})
						}
					}
					that.menuOptions = res.data.industries;

					for (let i = 0; i < res.data.staff_range.length; i++) {
						res.data.staff_range[i].value = res.data.staff_range[i].id;
						res.data.staff_range[i].label = res.data.staff_range[i].name;
					}

					that.staffSizeOptions = res.data.staff_range;
					that.getRules();
				}).catch(err => {
					message.error(err.data.message);
				});
			},
			onSubmit() {
				let that = this;
				let param = {
					name: this.formData.name,
					industry_id: this.formData.industry,
					staff_range_id: this.formData.staffSize,
				};
				if (this.formData.name == '') {
					message.error('请输入组织名称');
				} else {
					if (this.formData.logo) {
						param.logo = this.formData.logo;
					}
					that.$apiRequest.uploadFile('/department/orgReg', '', param, progress => {
						/*进度条*/

					}).then(res => {
						message.success('成功');
						this.getModelData();
						this.$emit('refash', {})
						this.fioiModelShow = false;
					}).catch(err => {
						message.error(err.data.message);
					});
				}
			},
			industryChange(id) {

				this.formData.industry = id;
			},
			staffSizeChange(e) {
			},
			removeFile() {
				this.imgSrc = '';
				this.formData.logo = '';
			},
			logoChange(e) {
				//上传图片并预览
				let file = e.target.files[0]; //获取第一个文件
				if (this.handleBeforeUpload(file)) {
					this.formData.logo = file;
					let img = new FileReader();
					img.readAsDataURL(file);
					img.onload = ({
						target
					}) => {
						this.imgSrc = target.result; //将img转化为二进制数据
					};
				} else {
					message.error('请上传图片');
				}
			},
			handleBeforeUpload(file) {
				var img = file.name.substring(file.name.lastIndexOf('.') + 1);
				const suffix = img === 'jpg';
				const suffix2 = img === 'png';
				const suffix3 = img === 'jpeg';
				return (suffix || suffix2 || suffix3);
			},
			openModel() {

				this.fioiModelShow = true;
			}
		}
	}
</script>

<style>
	.fioi_content_box {
		margin-top: 32px;
		padding: 0 138px;
		box-sizing: border-box;
	}

	.fioi_content_title {
		text-align: center;
		padding: 50px 0;
		font-size: 24px;
		font-weight: bold;
		box-sizing: border-box;
	}

	.fioi_upload_box {
		position: relative;

	}

	.fioi_upload_box:hover .ant-upload.ant-upload-select-picture-card {
		border-color: #09bd71;
	}

	.fioi_upload_btn {
		width: 104px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}

	.fioi_preview_box {
		width: 104px;
		height: 104px;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		cursor: pointer;
		border: 1px solid #D9D9D9;
		border-radius: 2px;
		margin: 0 8px 14px 0;
		background: #FAFAFA;
	}

	.fioi_preview_box>div {
		width: 100%;
		word-wrap: break-word;
		display: -webkit-box;

		overflow: hidden;

		white-space: normal !important;

		text-overflow: ellipsis;

		word-wrap: break-word;

		-webkit-line-clamp: 3;

		-webkit-box-orient: vertical
	}

	.fioi_delete_icon {
		font-size: 14px;
		color: #999999;
		cursor: pointer;
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 100;
	}

	.fioi_delete_icon:hover {
		color: red;
	}

	.fioi_submint_btn_box {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 100px;
		box-sizing: border-box;
	}

	.fioi_submint_btn {
		margin: 0 auto;
	}


	.fioi_info_card {
		width: 100%;
		height: 100%;
		padding: 30px;
		box-sizing: border-box;
		position: relative;
	}

	.fioi_info_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.fioi_info_top_title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
	}

	.fioi_info_top .fioi_info_top_btn {
		background: #09bd71;
		border-color: #09bd71;
	}

	.fioi_info_top .fioi_info_top_btn:hover {
		background: #09bd71;
		border-color: #09bd71;
		opacity: 0.7;
	}

	.fioi_info_subtitle {
		font-size: 14px;
	}

	.fioi_info_content {
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.fioi_info_content_picBox {
		height: 100px;
		width: 100px;
		border: 1px solid #EEEFF0;
		border-radius: 4px;
		overflow: hidden;
		margin-right: 40px;
	}

	.fioi_info_content_pic {
		height: 100%;
		width: 100%;
	}

	.fioi_info_content_textBox {}

	.fioi_info_content_text_item {
		font-size: 14px;
		color: #595959;
		margin-bottom: 24px;
		line-height: 1;
	}

	.fioi_info_content_text_item>span {
		color: #333333;
		font-weight: bold;
		margin-right: 10px;
	}

	.fioi_info_content_text_item:last-child {
		margin-bottom: 0;
	}
</style>
