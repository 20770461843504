<template>
	<!-- <div class="vueBox"> -->
	<div class="mbm_videoManage_box">
		<div style="display: none;" id="refash__data__481" @click="refashData">方法调用</div>
		<div v-if="false" class="mbm_videoManage_box_topBar">
			<div class="mbm_videoManage_box_topBar_item"
				:class="{'mbm_videoManage_box_topBar_item_active':topChange==1}" data-index="1"
				@click="changeTop($event)">活码</div>
			<div class="mbm_videoManage_box_topBar_item"
				:class="{'mbm_videoManage_box_topBar_item_active':topChange==2}" data-index="2"
				@click="changeTop($event)">回收站</div>
		</div>

		<div class="mbm_videoManage_box_content">
			<div v-if="!isCheck" class="mbm_videoManage_box_content_left">
				<div class="mbm_videoManage_box_content_left_title">目录</div>
				<div class="mbm_videoManage_box_content_left_content">
					<floader ref="floader" :floader_data="menuData" :allData="menuData" :currentId="currentId"
						:currentIndex="currentIndex" :loadingShow="floaderLoadingShow" :parentItem="{}"
						:selectItem="popoverSelectItem" :topChange="parseInt(topChange) ">
					</floader>
				</div>
			</div>


			<div class="mbm_videoManage_box_content_right">

				<div class="mbm_videoManage_box_content_right_topbar">
					<div class="mbm_allSelect_checkbox"
						:style="'align-items:'+(batchOperationStatus?'center':'flex-end')+';'">
						<a-checkbox :style="'width:'+(batchOperationStatus?'135px':0)+';'"
							style="overflow: hidden;word-break: keep-all;white-space: nowrap;transition: width 0.4s;"
							v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">
							已选中{{fileSelectList.length}}个文件
						</a-checkbox>
						<span class="mbm_morenD"
							v-if="!batchOperationStatus">{{currentTitle}}<span>(共{{total}})</span></span>
						<a-button v-if="batchOperationStatus" size="small" class="mbm_btn_louk" @click="batchDelete">
							批量删除</a-button>
						<a-button v-if="batchOperationStatus" size="small" class="mbm_btn_louk" @click="batchDownload">
							批量下载</a-button>

						<span v-if="false" class="mbm_batchOperation"
							@click="batchOperationStatus = !batchOperationStatus">{{batchOperationStatus?'退出':'批量操作'}}</span>
					</div>
					<div class="mbm_videoManage_box_content_right_topbar_right">
						<div class="mbm_genxin_time" @click="changeUpdataTime">
							更新时间
							<span class="mbm-iconfont mbm-jiantou"
								:style="{'transform':updataTime==1?'rotateX(180deg)':'rotateX(0)'}"></span>
						</div>
						<span class="mbm-iconfont mbm-fenleimokuai mbm_qrcode" @click="changeFileListStyle"></span>

						<div class="mbm_search_box">
							<input placeholder="输入标题搜索" v-model="searchValue"
								:style="(!batchOperationStatus?'width:200px;padding:0 15px;':'width:0;padding:0;')"
								style="transition:width 0.4s;" @keydown="enterSearch" />
							<div>
								<span class="mbm-iconfont mbm-suosou" @click="searchTitle"></span>
							</div>
						</div>
					</div>

				</div>
				<!-- 审核 -->
				<div v-if="isCheck">
					<div class="mbm_adminCheckBox">
						<div class="mbm_allSelect_checkbox">
							<a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate"
								@change="onCheckAllChange">已选中{{fileSelectList.length}}个文件
							</a-checkbox>
						</div>

						<div class="mbm_select_box">
							<div class="mbm_check_inputBox">
								<a-input v-model:value="checkInfo.uid" @pressEnter="checkInputChange"
									placeholder="请输入用户ID" />
								<span class="mbm-iconfont mbm-suosou" @click="checkInputChange"></span>
							</div>

							<div v-if="false" class="mbm_select_item">
								<span>排序</span>
								<a-select v-model:value="sortValue" label-in-value style="width: 120px"
									:options="sortOptions" @change="sortHandleChange">
								</a-select>
							</div>

							<div class="mbm_select_item">
								<span>类型</span>
								<a-select v-model:value="typeValue" label-in-value style="width: 120px"
									:options="typeOptions" @change="typeHandleChange">
								</a-select>
							</div>

							<div class="mbm_select_item">
								<span>审核</span>
								<a-select v-model:value="checkValue" label-in-value style="width: 120px"
									:options="checkOptions" @change="checkHandleChange">
								</a-select>
							</div>

						</div>
					</div>

					<div class="mbm_check_btnBox_box">
						<div class="mbm_check_btnBox">
							<a-button type="primary" class="mbm_check_btn" style="background: #304596;"
								@click="checkFiles('check',2)">不通过</a-button>
							<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;"
								@click="checkFiles('check',1)">通过</a-button>
							<a-button type="primary" class="mbm_check_btn" style="background: #C69D08;"
								@click="checkFiles('check',3)">有疑问</a-button>
							<a-button type="primary" class="mbm_check_btn" style="background: #000000;"
								@click="checkFiles('blacklist')">黑名单</a-button>
						</div>
						<div class="mbm_check_btnBox">
							<a-button type="primary" size="small" class="mbm_check_btn" style="background: #304596;"
								@click="openNotPassMsg(2)">不通过原因
							</a-button>
							<a-button type="primary" size="small" class="mbm_check_btn" style="background: #D60000;"
								@click="checkFiles('removeblacklist')">撤销黑名单
							</a-button>
							<a-button type="primary" size="small" class="mbm_check_btn" style="background: #8B996B;"
								@click="checkFiles('blackout')">停用通知</a-button>
							<a-button type="primary" size="small" class="mbm_check_btn" style="background: #B7168F;"
								@click="checkFiles('removeblackout')">
								撤销停用通知
							</a-button>
						</div>
					</div>

				</div>
				<!-- 加载动画 -->
				<loading defaultClass="mbm_loading" :loadingShow="loadingShow" :type="4" title=""
					:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''">
				</loading>

				<a-checkbox-group class="mbm_checkbox_group" v-model:value="fileSelectList" @change="checkBoxChange"
					:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''"
					v-if="fileListStyle == 1&&loadingShow==false&&currentId!=null&&currentIndex!=null">
					<a-radio-group name="radioGroup" v-model:value="radiofileSelectList"
						style="height:100%;width:100%;">
						<!-- 文件列表宫格样式 -->
						<div v-if="fileListStyle == 1&&loadingShow==false&&currentId!=null&&currentIndex!=null"
							class="mbm_box_content_right_fileList_gongge">
							<!-- 空页面 -->
							<div v-if="contentList.length==0"
								style="height: 100%;display: flex;align-items: center;position: absolute;width: 100%;top:0;left: 0;">
								<a-empty :image="simpleImage" style="flex: 1;" description="空空如也" />
							</div>
							<div class="mbm_box_content_right_fileList_gongge_content">
								<div class="mbm_file_item" v-for="(item,index) in contentList" :key="item.id"
									:data-index="index">
									<!-- model:多选选择框 -->
									<div v-if="isPageOrModel == 2">
										<div class="mbm_file_item_checkBox" v-if="multiple==1">
											<a-checkbox :value="item"></a-checkbox>
										</div>
										<div class="mbm_file_item_checkBox" v-if="multiple==2">
											<a-radio :value="item"></a-radio>
										</div>
									</div>

									<!-- 批量操作:多选选择框 -->
									<div v-if="batchOperationStatus" @click.stop="false"
										class="mbm_fileList_more_checkBox mbm_allSelect_checkbox mbm_file_item_checkBox">
										<a-checkbox :value="item"></a-checkbox>
									</div>
									<div class="mbm_file_item_top">
										<a-image @click="previewAndSetQrcode(item,1)" :src="item.cover_path"
											:preview="picPreview" :width="'100%'" :height="'100%'" />
										<div class="mbm_file_item_top_Vtag">v1</div>
										<span v-if="false" class="mbm-iconfont mbm-ico mbm_file_item_top_type"></span>
									</div>
									<div class="mbm_file_item_bottom">
										<div class="mbm_file_item_bottom_left">
											<div v-if="!((selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow)"
												class="mbm_file_item_bottom_left_title">
												{{item.filename?item.filename:'未命名'}}
											</div>
											<div v-if="(selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow"
												class="mbm_file_item_bottom_left_title">
												<input class="mbm_reName_input" ref="renameInput" :value="item.filename"
													@blur="fileRename($event,item)" v-focus />
											</div>
											<div class="mbm_file_item_bottom_left_subtitle">
												{{item.updated_at+' '+item.filesize}}
											</div>
										</div>
										<div>
											<a-popover placement="left" overlayClassName="mbm_defult_style_ji81"
												:visible="item.id==selectItem.id&&item.index==selectItem.index&&antPopoverShow"
												title="" trigger="click">
												<template #content style="padding: 0;">
													<div @click="hideAntPopover" class="mbm_handle_box_content_list"
														style="width: 166px;position: unset;">
														<div v-if="topChange == 1" @click="openFileRename($event,item)">
															<span class="mbm-iconfont mbm-xiugai"
																style="margin-right: 8px;font-size: 14px;"></span>重命名
														</div>
														<!-- <div v-if="topChange == 1" @click="changeLink($event,item)">
														<span class="mbm-iconfont mbm-icon-"
															style="margin-right: 8px;font-size: 14px;"></span>转换成链接
													</div> -->
														<div v-if="topChange == 1" @click="changeQrcode($event,item)">
															<span class="mbm-iconfont mbm-erweima"
																style="margin-right: 8px;font-size: 14px;"></span>转换成二维码
														</div>
														<div v-if="topChange == 1" @click="downLoadFile($event,item)">
															<span class="mbm-iconfont mbm-xiazai1"
																style="margin-right: 8px;font-size: 14px;"></span>下载源文件
														</div>
														<div v-if="topChange == 1"
															@click="openMoveFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-yidong"
																style="margin-right: 8px;font-size: 14px;"></span>移动到
														</div>
														<div v-if="topChange == 2"
															@click="openMoveFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-fenleimokuai"
																style="margin-right: 8px;font-size: 14px;"></span>恢复
														</div>
														<div class="mbm_divider_box">
															<a-divider style="margin: 0;paddiing:0;" />
														</div>

														<div @click="openDeleteFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-ashbin"
																style="margin-right: 8px;font-size: 14px;"></span>删除
														</div>
													</div>
												</template>
												<a-button v-if="isPageOrModel == 1"
													class="mbm_file_item_bottom_right mbm_open_handle_box"
													type="default" style="border: unset;padding: 0;"
													@click.stop='openAntPopover($event,item)'>
													<span class="mbm-iconfont mbm-sangediandian"
														style="font-size: 12px;"></span>
												</a-button>
											</a-popover>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a-radio-group>
				</a-checkbox-group>
				<!-- 文件列表样式 -->
				<a-checkbox-group class="mbm_checkbox_group" v-model:value="fileSelectList" @change="checkBoxChange"
					:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''"
					v-if="fileListStyle == 2&&loadingShow==false&&currentId!=null&&currentIndex!=null">
					<a-radio-group name="radioGroup" v-model:value="radiofileSelectList"
						style="height:100%;width:100%;">
						<div v-if="fileListStyle == 2&&loadingShow==false&&currentId!=null&&currentIndex!=null"
							:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''"
							class="mbm_box_content_fileList_List">
							<!-- 空页面 -->
							<div v-if="contentList.length==0"
								style="height: 100%;display: flex;align-items: center;position: absolute;width: 100%;top:0;left: 0;">
								<a-empty :image="simpleImage" style="flex: 1;" description="空空如也" />
							</div>
							<a-image-preview-group>
								<div class="mbm_videoQrcode_item mbm_user_select_none "
									:class="{'mbm_videoQrcode_item_active':fileIndex==index}"
									v-for="(item,index) in contentList" :key="item.id" :data-index="index"
									@click="changeFile(index,item,1)">

									<!-- model:多选选择框 -->
									<div v-if="isPageOrModel == 2">
										<div @click.stop="false"
											class="mbm_fileList_more_checkBox mbm_allSelect_checkbox"
											v-if="multiple==1">
											<a-checkbox :value="item"></a-checkbox>
										</div>
										<div @click.stop="false"
											class="mbm_fileList_more_checkBox mbm_allSelect_checkbox"
											v-if="multiple==2">
											<a-radio :value="item"></a-radio>
										</div>
									</div>
									<!-- model:多选选择框 -->
									<div v-if="batchOperationStatus" @click.stop="false"
										class="mbm_fileList_more_checkBox mbm_allSelect_checkbox">
										<a-checkbox :value="item"></a-checkbox>
									</div>
									<div class="mbm_videoQrcode_item_left" style="margin-right: 0;width: 100%;">
										<div class="mbm_videoQrcode_item_left_left">


											<div class="mbm_videoQrcode_cover_imgBox" :style="pageType == 3?'height:70px;width:39px':''">
												<a-image :src="item.cover_path" :preview="picPreview" :width="'100%'"
													:height="'100%'" />
											<!-- 	<span>v1</span> -->
											</div>

											<div class="mbm_videoQrcode_item_left_textBox">
												<div class="mbm_videoQrcode_item_title ">
													<div
														v-if="!((selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow)">

														<span class="text_overHidden"
															style="max-width: 70%;display: inline-block;vertical-align: middle;">{{item.filename?item.filename:'未命名'}}</span>
														<span class="mbm-iconfont mbm-xiugai mbm_rename_btn"
															style="width: 20%;vertical-align: middle;"
															@click.stop="openFileRename($event,item)"></span>
													</div>


													<input @click.stop="false"
														v-if="(selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow"
														class="mbm_reName_input" ref="renameInput" :value="item.title"
														@blur="fileRename($event,item)" v-focus />
												</div>
												<div class="mbm_scanCount" v-if="item.code_statistic">
													<span>今日扫描{{item.code_statistic.today_totall}}次</span>
													<span>昨日扫描{{item.code_statistic.yesterday_totall}}次</span>
													<span>近7日扫描{{item.code_statistic.nearly7_totall}}次</span>
													<span>近30日扫描{{item.code_statistic.totall}}次</span>
												</div>
												<div class="mbm_expire_tip" v-if="item.expire_tip">
													{{item.expire_tip}}
												</div>
											</div>

											<div>
												<span v-if="item.filesize"
													class="mbm_file_size">{{item.filesize}}</span>
												<span class="mbm_file_time">{{item.updated_at}}</span>
											</div>
										</div>
										<div class="mbm_videoQrcode_item_left_right">
											<a-popover placement="left" overlayClassName="mbm_defult_style_ji81"
												:visible="item.id==selectItem.id&&item.index==selectItem.index&&antPopoverShow"
												title="" trigger="click">
												<template #content style="padding: 0;">

													<div @click="hideAntPopover" class="mbm_handle_box_content_list"
														style="width: 166px;position: unset;">
														<div v-if="topChange == 1" @click="openFileRename($event,item)">
															<span class="mbm-iconfont mbm-xiugai"
																style="margin-right: 8px;font-size: 14px;"></span>重命名
														</div>
														<!-- <div v-if="topChange == 1" @click="changeLink($event,item)">
														<span class="mbm-iconfont mbm-icon-"
															style="margin-right: 8px;font-size: 14px;"></span>转换成链接
													</div> -->
														<div v-if="topChange == 1" @click="changeQrcode($event,item)">
															<span class="mbm-iconfont mbm-erweima"
																style="margin-right: 8px;font-size: 14px;"></span>转换成二维码
														</div>
														<div v-if="topChange == 1" @click="downLoadFile($event,item)">
															<span class="mbm-iconfont mbm-xiazai1"
																style="margin-right: 8px;font-size: 14px;"></span>下载源文件
														</div>
														<div v-if="topChange == 1"
															@click="openMoveFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-yidong"
																style="margin-right: 8px;font-size: 14px;"></span>移动到
														</div>
														<div v-if="topChange == 2"
															@click="openMoveFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-fenleimokuai"
																style="margin-right: 8px;font-size: 14px;"></span>恢复
														</div>
														<div class="mbm_divider_box">
															<a-divider style="margin: 0;paddiing:0;" />
														</div>

														<div @click="openDeleteFile($event,selectItem.name)">
															<span class="mbm-iconfont mbm-ashbin"
																style="margin-right: 8px;font-size: 14px;"></span>删除
														</div>
													</div>
												</template>
												<a-button v-if="isPageOrModel == 1" class="mbm_open_handle_box"
													type="default" style="border: unset;"
													@click.stop='openAntPopover($event,item)'>
													<span class="mbm-iconfont mbm-sangediandian"
														style="font-size: 14px;"></span>
												</a-button>
											</a-popover>
										</div>
									</div>

									<!-- 	<div class="mbm_videoQrcode_item_right" @click.stop="false">
									<a-image :src="item.path" :preview="true" :width="'100%'" :height="'100%'" />
								</div> -->


								</div>
							</a-image-preview-group>
						</div>
					</a-radio-group>
				</a-checkbox-group>
				<div class="mbm_videoManage_box_content_right_footer">
					<a-config-provider :locale="configProvider.lang">
						<a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="total"
							@change="pageChange" />
					</a-config-provider>
				</div>
			</div>
		</div>
	</div>

	<!-- </div> -->
	<!-- 移动model -->
	<a-modal :title="'将当前文件移动至“'+moveCurrentName+'”'" v-model:visible="moveFileModelShow" :footer="null" :width="600">
		<div class="mbm_move_model_center_body">
			<div>
				<removeFloader :remove_floader_data="videoData" :currentId="parseInt(moveCurrentId)"
					:currentIndex="parseInt(moveCurrentIndex)" :noSelectList="{}" :parentItem="{}">
				</removeFloader>

			</div>

		</div>
		<div class="mbm_move_model_center_footer">
			<div class="mbm_move_model_center_footer_left">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="moveModelNewChild"><span
						class="mbm-iconfont mbm-jia" style="font-size: 14px;"></span><span>新建目录</span></a-button>
			</div>
			<div class="mbm_move_model_center_footer_right">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="closeMoveFileModel">取消</a-button>
				<a-button type="primary" :loading="confirmLoading"
					class="mbm_move_model_center_footer_btn mbm_move_define" @click.stop="moveFile">确定
				</a-button>
			</div>
		</div>
	</a-modal>

	<!-- 预览和设置文件model -->
	<!-- <a-modal title="预览和设置" v-model:visible="mbmPreviewModelShow" :footer="null" :centered="true" :destroyOnClose="true"
		:width="1000">
		<div class="mbm_set_box">
			<settings :showConfig="showConfig" :styleConfig="styleConfig" :idKey="selectItem.id_key"></settings>
		</div>
		<div v-if="isCheck" class="mbm_adminCheckBox_model">
			<div class="mbm_check_btnBox">
				<a-button type="primary" size="small" class="mbm_check_btn" style="background: #304596;"
					@click="openNotPassMsg(1)">不通过原因
				</a-button>
				<a-button type="primary" size="small" class="mbm_check_btn" style="background: #D60000;"
					@click="checkOneFile('removeblacklist')">撤销黑名单
				</a-button>
				<a-button type="primary" size="small" class="mbm_check_btn" style="background: #8B996B;"
					@click="checkOneFile('blackout')">停用通知</a-button>
				<a-button type="primary" size="small" class="mbm_check_btn" style="background: #B7168F;"
					@click="checkOneFile('removeblackout')">撤销停用通知
				</a-button>

				<a-button type="primary" class="mbm_check_btn" @click="checkOneFile('del')" danger>删除</a-button>

				<a-button type="primary" class="mbm_check_btn" style="background: #C69D08;"
					@click="checkOneFile('check',3)">有疑问
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #000000;"
					@click="checkOneFile('blacklist')">黑名单
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #EA31FC;"
					@click="editQrcode($event,selectItem)">编辑
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #304596;"
					@click="checkOneFile('check',2)">不通过
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;"
					@click="checkOneFile('check',1)">通过
				</a-button>
			</div>
		</div>
	</a-modal> -->
	<!-- 黑名单modal -->
	<a-modal title="信息" v-model:visible="blackListModelShow" :footer="null" :destroyOnClose="true" :width="416">

		<div>
			加入黑名单后, 是否删除该链接下所有的音频和视频,
			<span style="color:red;">
				删除后不可恢复, 请谨慎操作 </span>
		</div>
		<div class="mbm_blackListModel_btnBox">
			<a-button @click="blackListMdCancel">取消</a-button>
			<a-button type="primary" @click="justBlackList"
				style="background: #FFFFFF;border-color:#000000;color:#000000;">只加入黑名单
			</a-button>
			<a-button type="primary" @click="deleteAndBlackList" style="background: #000000;border-color:#000000;">删除并加入
			</a-button>
		</div>
	</a-modal>

	<!-- 停用modal -->
	<a-modal title="请输入停用原因" v-model:visible="stopUseModelShow" :footer="null" :destroyOnClose="true" :width="416">
		<a-input v-model:value="blockMsg" placeholder="请输入停用原因" />
		<div class="mbm_blackListModel_btnBox">
			<a-button @click="stopUseMdCancel">取消</a-button>
			<a-button type="primary" @click="stopUse"
				style="background: rgb(139, 153, 107);border-color:rgb(139, 153, 107);">停用
			</a-button>
		</div>
	</a-modal>

	<!-- 不通过原因modal -->
	<a-modal title="请输入不通过原因" v-model:visible="notPassModelShow" :footer="null" :destroyOnClose="true" :width="416">
		<a-input v-model:value="notPassMsg" placeholder="请输入不通过原因" />
		<div class="mbm_blackListModel_btnBox">
			<a-button @click="notPassMdCancel">取消</a-button>
			<a-button type="primary" @click="notPass"
				style="background: rgb(48, 69, 150);border-color:rgb(48, 69, 150);">审核不通过
			</a-button>
		</div>
	</a-modal>

	<!-- 预览文件model -->
	<a-modal :zIndex="2001" class="mbm_player_model" :title="selectItem.file_type==1?'视频播放器':'音频播放器'"
		v-model:visible="mbmPlayerModelShow" :footer="null" :destroyOnClose="true" :width="600">

		<player :pageType="selectItem.file_type" :info="selectItem"></player>
		<!-- <span>{{selectItem}}</span> -->
		<div v-if="isCheck" class="mbm_adminCheckBox_model">
			<div class="mbm_check_btnBox">
				<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;" @click="checkOneFile(1)">通过
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #C69D08;" @click="checkOneFile(2)">有疑问
				</a-button>
				<a-button type="primary" class="mbm_check_btn" @click="checkOneFile(3)" danger>删除</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #000000;" @click="checkOneFile(4)">
					删除+加入黑名单</a-button>
			</div>
		</div>
	</a-modal>

	<!-- 统计modal -->
	<!-- <a-modal title="" v-model:visible="statisticModelShow" :footer="null" :destroyOnClose="true" :width="850">
		<div style="height: 600px;width: 100%;overflow: hidden;overflow-y: auto;">
			<statisticModel :info="statisticInfo"></statisticModel>
		</div>

	</a-modal> -->
</template>

<script>
	import $ from 'jquery';
	import {
		ref,
		watch
	} from "vue";
	import {
		operation
	} from '/src/assets/js/operation.js';
	import bus from '/src/assets/js/bus.js';
	import floader from '/src/components/floader.vue';
	import removeFloader from '/src/components/removeFloader.vue';
	import loading from '/src/components/loading.vue';
	import player from '/src/components/player.vue';
	import {
		Empty,
		Modal,
		message
	} from 'ant-design-vue';
	import md5 from 'md5';
	import baseFunc from '../../config/base_func.js';
	import zhCN from 'ant-design-vue/es/locale/zh_CN';
	export default {
		name: 'AttachManage',
		components: {
			floader,
			removeFloader,
			loading,
			player
			// mbm_page,
			// settings,
			// statisticModel
		},
		emits: ['getlistsfromLib'],
		props: {
			isPageOrModel: {
				type: Number,
				default: 1,
			}, //1:页面；2：弹窗
			modalType: {
				type: Number,
				default: 0,
			},
			multiple: Number,
		},
		watch: {
			modalType: function(newV, oldV) {
				pageType = newV;
				this.pageType = newV;
				this.fileSelectList = [];
				this.radiofileSelectList = null;
				this.refashData();
			}
		},
		data() {
			return {
				configProvider: {
					lang: zhCN,
					emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
				},
				mbmPlayerModelShow: false,
				picPreview: true,
				batchOperationStatus: false, //批量操作状态
				previewType: {
					visible: false,
					onVisibleChange: function(visible, prevVisible) {

					},
					getContainer: '',
				},
				defaultAvatarImg: 'this.src="/qrcode/image/default_head_image.png"',
				//统计信息
				statisticModelShow: false,
				statisticInfo: {
					overall: 1, // 1单个码 ,2：超级管理员,3：普通用户
					id_key: '',
				},
				// 不通过原因modal
				notPassModelShow: false,
				notPassParam: {},
				notPassMsg: '',
				notPassType: 1,
				// 停用modal
				stopUseModelShow: false,
				stopUseParam: {},
				blockMsg: '',
				stopUseType: 1,
				// 黑名单modal
				blackListModelShow: false,
				blackParam: {},
				blackListType: 1,
				// 审核-start
				isCheck: false, //是否审核
				checkInfo: {
					status: 0,
					qrcode_type: 0,
					uid: '',
					status_msg: '',
				},
				checkDocument: '',
				indeterminate: false,
				checkAll: false,
				sortValue: {
					value: 1,
					label: '默认'
				},
				sortOptions: [{
					value: 1,
					label: '默认'
				}, {
					value: 2,
					label: '日扫描量'
				}, {
					value: 3,
					label: '昨日扫描量'
				}, {
					value: 4,
					label: '近7日扫描量'
				}, {
					value: 5,
					label: '近30日扫描量'
				}, {
					value: 6,
					label: '按用户排序'
				}, ],
				typeValue: {
					value: 0,
					label: '全部'
				},
				typeOptions: [{
					value: 0,
					label: '全部'
				}, {
					value: 1,
					label: '视频'
				}, {
					value: 2,
					label: '音频'
				}, ],
				checkValue: {
					value: 0,
					label: '待审核'
				},
				checkOptions: [{
					value: 0,
					label: '待审核'
				}, {
					value: 1,
					label: '审核通过'
				}, {
					value: 2,
					label: '审核不通过'
				}, {
					value: 3,
					label: '有疑问'
				}, {
					value: 4,
					label: '黑名单'
				}, {
					value: 5,
					label: '停用'
				}, {
					value: -1,
					label: '全部'
				}, ],
				// 审核-end
				mbmPreviewModelShow: false,
				//必填
				idKey: 't1_NqatX',
				//样式设置，可不填
				styleConfig: {
					iframe: {
						// width:'200px',//默认320px
						// height:'400px',//默认900px
						height: 'auto', //默认900px
						//...
					},
					settings: {
						// width:'300px',//默认500px
						//...
					}
				},
				//显示内容设置，可不填
				showConfig: {
					iframe: true, //显示左侧预览iframe
					settings: true, //显示右侧设置整体
					settings_qrcode: true, //设置头部二维码图片+标题
					settings_list: true, //二维码设置部分收起/展开
					settings_list_expire: true, //设置有效期
					settings_list_auth: true, //设置查看权限
					settings_list_open: true, //设置打开方式（链接类型此项不论设置什么值最终都为false）
					settings_list_bot: true, //底部设置
					settings_list_video: true //视频下载设置
				},

				pageType: 1, //类型 1：视频库 2：音频库 3：图片库 4：二维码 5：链接’
				fileSelectList: [],
				radiofileSelectList: {},
				confirmLoading: false,
				moveCurrentId: 0,
				moveCurrentIndex: 1,
				moveCurrentName: '全部活码',
				moveFileModelShow: false,
				antPopoverShow: false,
				renameInputShow: false,

				simpleImage: Empty.PRESENTED_IMAGE_SIMPLE, //空页面图片
				pageSize: 20, //每页显示20条数据
				currentPage: 1, //当前页码
				total: 0, //总记录数

				popoverSelectItem: {}, //文件夹当前选中文件夹
				selectItem: [],
				selectDocument: null,
				btnList: ["打开方式", "统计", "编辑", "下载", "复制", "删除"],
				selectItemToTop: 0,
				selectItemToLeft: 0,
				handleShow: false,
				handleId: null,
				loadingShow: true,
				floaderLoadingShow: true,
				fileIndex: 0,
				topChange: 1,
				/*1:视频文件。2:回收站*/
				updataTime: 1,
				/*1:降序。2:升序*/
				fileListStyle: 1,
				/*1:宫格。2:列表*/
				searchValue: '',
				currentId: 0,
				/*当前选中目录id*/
				currentIndex: 1,
				currentTitle: '活码',
				/*当前层级*/
				/*当前目录下标*/
				menuData: [],
				videoData: [],
				backData: [],
				contentList: [],
			}
		},
		created() {
			let that = this;
			if (that.modalType != 0) {
				pageType = that.modalType;
			}
			that.pageType = pageType;
			if (typeof check_power != 'undefined' && parseInt(check_power) == 1) {
				this.isCheck = true;
			}

			this.getCatalogue(); //获取目录
			this.getCatalogueData() //获取文件夹下数据
			this.catalogueListener(); //文件夹监听事件

			//文件移动model切换当前选中的文件夹
			bus.$on('removeCatalogueChange', e => {
				this.moveCurrentId = parseInt(e.id);
				this.moveCurrentIndex = parseInt(e.index);
				this.moveCurrentName = e.name;

			});
			//监听选中文件参数
			watch(
				() => this.fileSelectList,
				val => {
					this.$emit('getlistsfromLib', this.fileSelectList);
					if (this.contentList.length != 0) {
						this.indeterminate = !!this.fileSelectList.length && this.fileSelectList.length < this
							.contentList
							.length;
						this.checkAll = this.fileSelectList.length === this.contentList.length;
					} else {
						this.indeterminate = false;
						this.checkAll = false;
					}
				},
			);
			watch(
				() => this.radiofileSelectList,
				val => {
					this.$emit('getlistsfromLib', this.radiofileSelectList);
				},
			);
		},
		beforeDestroy() {

		},
		methods: {
			// 刷新数据
			refashData() {
				this.getCatalogue();
				this.getCatalogueData(1);
			},

			/* 隐藏不通过原因modal */
			notPassMdCancel() {
				this.notPassModelShow = false;
			},
			/* 不通过 */
			notPass() {
				let param = {
					id_keys: this.notPassParam.id_keys,
					handle_type: this.notPassParam.handle_type,
					status: this.notPassParam.status,
					status_msg: this.notPassMsg,
				}

				this.checkSubmit(param, this.notPassType);
				this.notPassModelShow = false;
				this.mbmPreviewModelShow = false;
			},
			/* 隐藏停用modal */
			stopUseMdCancel() {
				this.stopUseModelShow = false;
			},
			/* 停用 */
			stopUse() {
				let param = {
					id_keys: this.stopUseParam.id_keys,
					handle_type: this.stopUseParam.handle_type,
					blockMsg: this.blockMsg,
				}
				this.checkSubmit(param, this.stopUseType);
				this.stopUseModelShow = false;
				this.mbmPreviewModelShow = false;
			},
			/* 隐藏黑名单modal */
			blackListMdCancel() {
				this.blackListModelShow = false;
			},
			/* 只加入黑名单 */
			justBlackList() {
				let param = {
					id_keys: this.blackParam.id_keys,
					handle_type: this.blackParam.handle_type,
					delete_file: 0,
				}
				this.checkSubmit(param, this.blackListType);
				this.blackListModelShow = false;
				this.mbmPreviewModelShow = false;
			},
			/* 加入并删除 */
			deleteAndBlackList() {
				let param = {
					id_keys: this.blackParam.id_keys,
					handle_type: this.blackParam.handle_type,
					delete_file: 1,
				}
				this.checkSubmit(param, this.blackListType);
				this.blackListModelShow = false;
				this.mbmPreviewModelShow = false;

			},
			/* 审核文件发送请求 */
			checkSubmit(param, type, delete_file) {
				let that = this;
				that.$apiRequest.post("/attach/checkQrManage", param).then(res => {

					if (res.data.code == 200) {

						message.success(res.data.message);

						if (type == 1) {
							let index = that.contentList.findIndex(item => {
								if (item.id_key == param.id_keys) {
									return true;
								}

							});
							that.contentList.splice(index, 1);
							that.total = that.total - 1;
						} else if (type == 2) {
							that.total = that.total - that.fileSelectList.length;
							for (let i = 0; i < that.fileSelectList.length; i++) {

								let index = that.contentList.findIndex(item => {
									if (item.id_key == that.fileSelectList[i]) {
										return true;
									}

								});
								that.contentList.splice(index, 1);
							}
						}
					}
					that.fileSelectList = [];
					that.indeterminate = false;
					that.checkAll = false;
				}).catch(err => {
					that.fileSelectList = [];
					that.indeterminate = false;
					that.checkAll = false;
					baseFunc.checkState(err.data);
				});
			},
			/* 审核确认model */
			checkModel(param, type) {
				let that = this;
				let content = '';

				switch (param.status) {
					case 1:
						content = '通过';
						break;
					case 2:
						content = '不通过';
						break;
					case 3:
						content = '有疑问';
						break;
				};
				let contentHtml;
				switch (param.handle_type) {
					case 'del':
						contentHtml = h => < span > 是否删除该链接下所有的音频和视频, < span style = "color:red;" >
							删除后不可恢复, 请谨慎操作 < /span></span > ;
						break;
					case 'check':
						contentHtml = h => < span > 确认执行 < span style = "color:red;" > {
							content
						} < /span>操作</span > ;
						break;
					case 'removeblacklist':
						contentHtml = h => < span > 确认 < span style = "color:red;" >
							恢复为正常访问 ? < /span></span > ;
						content = '撤销黑名单';
						break;
					case 'removeblackout':
						contentHtml = h => < span > 确认 < span style = "color:red;" >
							撤销停用 ? < /span></span > ;
						content = '撤销停用';
						break;
				};

				Modal.confirm({
					title: '信息',
					content: contentHtml,
					okText: content,
					cancelText: '取消',
					icon: h => < span class = "mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang" > < /span>,
					onOk() {

						that.checkSubmit(param, type);
						that.mbmPreviewModelShow = false;

					},
					onCancel() {

					},
				});

			},

			/* 不通过原因 */
			openNotPassMsg(type) { //单个：type:1,多个type:2
				let id_keys = '';
				this.notPassType = type;
				if (type == 1) {

					id_keys = this.selectItem.id_key + '';
					this.notPassModelShow = true;
				} else {

					if (this.fileSelectList.length != 0) {
						for (let i = 0; i < this.fileSelectList.length; i++) {
							id_keys += this.fileSelectList[i] + '';
							if (i != this.fileSelectList.length - 1) {
								id_keys += ','
							}
						};
						this.notPassModelShow = true;
					} else {
						message.error('未选择');
					}

				}

				this.notPassParam = {
					id_keys: id_keys,
					handle_type: 'check',
					status: 2,
				};

			},
			/* 审核一个文件 */
			checkOneFile(handle_type, status) {
				let param = {};
				let id_keys = this.selectItem.id_key;
				if (handle_type == 'blacklist') {

					this.blackParam = {
						id_keys: id_keys,
						handle_type: handle_type,
					};
					this.blackListType = 1;
					this.blackListModelShow = true;
				} else if (handle_type == 'blackout') {
					this.stopUseParam = {
						id_keys: id_keys,
						handle_type: handle_type,
					};
					this.stopUseType = 1;
					this.stopUseModelShow = true;
				} else {
					switch (handle_type) {
						case 'del':
							param = {
								id_keys: id_keys,
								handle_type: handle_type
							};

							break;
						case 'check':
							if (status == 2) {
								param = {
									id_keys: id_keys,
									handle_type: handle_type,
									status: status,
									status_msg: ''
								};

							} else {
								param = {
									id_keys: id_keys,
									handle_type: handle_type,
									status: status,
								};
							}
							break;
						case 'removeblacklist':
							param = {
								id_keys: id_keys,
								handle_type: handle_type
							};
							break;
						case 'removeblackout':
							param = {
								id_keys: id_keys,
								handle_type: handle_type
							};
							break;

					};
					this.checkModel(param, 1);

				}

				this.mbmPlayerModelShow = false;
			},
			/* 审核多个文件 */
			checkFiles(handle_type, status) {
				let param = {};
				let id_keys = '';
				if (this.fileSelectList.length != 0) {
					for (let i = 0; i < this.fileSelectList.length; i++) {
						id_keys += this.fileSelectList[i] + '';
						if (i != this.fileSelectList.length - 1) {
							id_keys += ','
						}
					};
					if (handle_type == 'blacklist') {

						this.blackParam = {
							id_keys: id_keys,
							handle_type: handle_type,
						};
						this.blackListType = 2;
						this.blackListModelShow = true;
					} else if (handle_type == 'blackout') {
						this.stopUseParam = {
							id_keys: id_keys,
							handle_type: handle_type,
						};
						this.stopUseType = 2;
						this.stopUseModelShow = true;
					} else {
						switch (handle_type) {
							case 'del':
								param = {
									id_keys: id_keys,
									handle_type: handle_type
								};

								break;
							case 'check':
								if (status == 2) {
									param = {
										id_keys: id_keys,
										handle_type: handle_type,
										status: status,
										status_msg: ''
									};

								} else {
									param = {
										id_keys: id_keys,
										handle_type: handle_type,
										status: status,
									};
								}
								break;
							case 'removeblacklist':
								param = {
									id_keys: id_keys,
									handle_type: handle_type
								};
								break;
							case 'removeblackout':
								param = {
									id_keys: id_keys,
									handle_type: handle_type
								};
								break;

						};
						this.checkModel(param, 2);
					}
				} else {
					message.error('未选择');
				};

			},
			//排序改变
			sortHandleChange(e) {

			},
			//类型改变
			typeHandleChange(e) {

				this.checkInfo.qrcode_type = e.value;

				//获取目录
				this.getCatalogue();
				this.loadingShow = true;
				this.getCatalogueData(); //获取文件夹下数据
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				this.indeterminate = false;
				this.checkAll = false;
			},
			//审核改变
			checkHandleChange(e) {
				this.currentPage = 1;
				this.checkInfo.status = e.value;

				//获取目录
				this.getCatalogue();
				this.loadingShow = true;
				this.getCatalogueData(); //获取文件夹下数据
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				this.indeterminate = false;
				this.checkAll = false;
			},
			//用户ID搜索
			checkInputChange(e) {
				this.currentPage = 1;
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				this.indeterminate = false;
				this.checkAll = false;
				//获取目录
				this.getCatalogue();
				this.loadingShow = true;
				this.getCatalogueData(); //获取文件夹下数据
			},
			/* 全选 */
			onCheckAllChange(e) {

				let id_keysList = [];
				for (let i = 0; i < this.contentList.length; i++) {
					id_keysList.push(this.contentList[i]);
				}
				this.fileSelectList = e.target.checked ? id_keysList : [];
				this.indeterminate = false;

			},
			//批量删除
			batchDelete() {
				if (this.fileSelectList.length == 0) {
					message.error('未选择二维码');
				} else {
					operation(8, this.fileSelectList);
				}

			},
			//批量下载
			batchDownload() {
				if (this.fileSelectList.length == 0) {
					message.error('未选择二维码');
				} else {
					operation(11, this.fileSelectList);
				}
			},
			// 文件多选框改变
			checkBoxChange(e) {
				let fileSelectList = e;
				this.$emit('getlistsfromLib', this.fileSelectList);
			},
			//获取目录
			getCatalogue(isRefash) {
				let that = this;
				that.floaderLoadingShow = true;
				if (!isRefash) {
					that.floaderLoadingShow = false;
				}
				//获取目录
				this.$apiRequest.post("/attach/getCatalogue", {
					type: this.pageType,
					check_power: typeof check_power != 'undefined' ? check_power : '',
				}).then(res => {
					this.videoData = res.data.data;
					if (this.topChange == 1) {
						this.menuData = this.videoData;
					} else {
						this.menuData = this.backData;
					}
					this.currentTitle = this.menuData[0].title;
					this.floaderLoadingShow = false;
				}).catch(err => {
					baseFunc.checkState(err.data);
					this.floaderLoadingShow = false;
				});
			},
			//获取文件夹数据
			getCatalogueData(status) {
				let that = this;
				that.loadingShow = true;
				if (status == 1) {
					this.currentPage = 1;
				}
				let param = {
					id: this.currentId,
					type: this.pageType,
					size: this.pageSize,
					page: this.currentPage,
					sort: this.updataTime,
					keywords: this.searchValue,
					check_power: '',
				};
				if (typeof check_power != 'undefined' && check_power == 1) {
					param.check_power = typeof check_power != 'undefined' ? check_power : '';
					param.status = this.checkInfo.status;
					param.qrcode_type = this.checkInfo.qrcode_type;
					param.uid = this.checkInfo.uid;
				}
				this.$apiRequest.post("/attach/CatalogData", param).then(res => {
					if (res.data.code == 200) {
						that.loadingShow = false;
						for (let i = 0; i < res.data.data.data.length; i++) {
							if (res.data.data.data[i].avatarUrl) {
								res.data.data.data[i].avatarUrl = baseFunc.getAliOssAuthKey(res.data.data.data[i]
									.avatarUrl);
							} else {
								res.data.data.data[i].avatarUrl = "/qrcode/image/default_head_image.png"
							}
						}
						that.contentList = res.data.data.data;
						that.total = res.data.data.total;
					}
				}).catch(err => {
					that.loadingShow = false;
					baseFunc.checkState(err.data);
				});
			},
			//文件夹监听事件
			catalogueListener() {
				let that = this;
				//文件夹切换
				bus.$on('catalogueChange', e => {
					let that = this;
					this.currentPage = 1;
					this.currentId = parseInt(e.id);
					this.currentIndex = parseInt(e.index);
					this.currentTitle = e.title;
					this.loadingShow = true;
					this.fileSelectList = [];
					this.radiofileSelectList = '';
					if (this.topChange == 1) {
						//获取文件夹数据
						this.getCatalogueData();
					} else if (this.topChange == 2) {
						//获取回收站数据
						this.getRecycleData();
					}
				});
				//气泡框文件夹选择
				bus.$on('popoverSelect', e => {
					this.popoverSelectItem = e.item;
				});
				//删除文件夹
				bus.$on('deleteCatalogue', e => {
					let id = e.id;
					let type = e.type; //1:气泡框；2：移动model

					this.$apiRequest.post("/attach/deleteFile", {
						id: id,
					}).then(res => {
						if (res.data.code == 200) {
							if (type == 1) {
								message.success('目录删除成功');
							}
							that.getCatalogue(false);
						}
					}).catch(err => {
						message.error(err.data.message);
						baseFunc.checkState(err.data);
					});
				});
				//新建子目录
				bus.$on('newChild', e => {
					let id = e.id;
					let index = e.index;
					let type = e.type;
					this.$apiRequest.post("/attach/newlyBuildFile", {
						id: id,
						type: this.pageType,
					}).then(res => {
						let item = res.data.data;
						let arry = {
							index: index + 1
						};
						for (var i in arry) {
							item[i] = arry[i]
						}
						if (res.data.code == 200) {
							that.getCatalogue(false);
							bus.$emit(type == 1 ? 'openRenameChild' : 'openRenameModelChild', {
								'item': item,
							})
							this.currentId = parseInt(item.id);
						
							this.currentTitle = item.title;
							setTimeout(function() {
								message.success('目录新建成功');	
							}, 1000);
							
						}
					}).catch(err => {
						message.error(err.data.message);
						baseFunc.checkState(err.data);
					});
				});
				//文件夹移动
				bus.$on('moveCatalogue', e => {
					let id = e.id;
					let pid = e.pid;
					this.$apiRequest.post("/attach/moveFile", {
						id: id,
						pid: pid,
					}).then(res => {
						if (res.data.code == 200) {
							message.success('目录移动成功');
							that.getCatalogue(false);
							// this.currentId = parseInt(e.pid);
							// this.currentIndex = parseInt(e.index);
							// this.currentTitle = e.title;
						}
					}).catch(err => {
						message.error(err.data.message);
						baseFunc.checkState(err.data);
					});
				});
				//文件夹重命名
				bus.$on('rename', e => {
					let id = e.id;
					let index = e.index;
					let value = e.value;
					this.$apiRequest.post("/attach/renameFile", {
						id: id,
						title: value,
					}).then(res => {
						if (res.data.code == 200) {
							message.success('文件夹重命名成功');
							that.getCatalogue(false);
						}
					}).catch(err => {
						that.getCatalogue();
						message.error(err.data.message);
						baseFunc.checkState(err.data);
					});
				});
			},
			/* 转换二维码 */
			changeQrcode(e, item) {
				window.open(item.transition_qrcode_url);
			},
			//下载文件
			downLoadFile(e, item) {
				this.$apiRequest.post("/attach/downloadAttachJudge", {
					attach_id: item.id,
				}).then(res => {
					/* 接口返回为true时执行下载 */
					var eleLink = document.createElement('a');
					eleLink.style.display = 'none';
					//eleLink.rel = "noreferrer noopener nofollow"
					//eleLink.target = "_blank"
					eleLink.href = item.download_file_url;
					eleLink.click();
					// 然后移除
					eleLink.remove();
				}).catch(err => {
					baseFunc.checkState(err.data, '下载提示');
				});

				// this.$apiRequest.post("/attach/getDownloadFile", {
				// 	id: this.selectItem.id,
				// 	type: this.pageType,
				// }).then(res => {


				// 	if (res.data.code == 200) {
				// 		let url = baseFunc.getQiniusign(res.data.download_link);

				// 		window.location.href = url;
				// 	}
				// }).catch(err => {
				// 	Modal.info({
				// 		title: () => err.data.message,
				// 		content: '',
				// 		okText: '我知道了',
				// 		onOk() {

				// 		},
				// 	});


				// });
			},
			//打开删除文件
			openDeleteFile() {
				let that = this;
				// operation('8', that.selectItem)
				let name = this.selectItem.filename;
				let id = this.selectItem.id;
				let index = this.selectItem.index;
				let title = '';
				let content = '';
				let delType = 1;
				title = '确认删除该附件吗？';
				content = '当前删除，该附件将无法找回，与该附件关联的二维码也将无法查看，请谨慎操作!';
				Modal.confirm({
					title: title,
					content: h => < div style = "color:red;" > {
						content
					} < /div>,
					okText: '确认',
					okType: 'danger',
					cancelText: '取消',
					icon: h => < span class = "mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang" > < /span>,
					onOk() {
						that.$apiRequest.post("/attach/getDeleteFile", {
							id: id,
							type: that.pageType,
							del: delType,
						}).then(res => {
							if (res.data.code == 200) {
								if (delType == 1) {
									message.success(res.data.message);
									that.getCatalogueData();
								} else {
									message.success(res.data.message);
									that.getRecycleData();
								}
							}
						}).catch(err => {
							baseFunc.checkState(err.data);
						});
					},
					onCancel() {

					},
				});
			},

			/* 移动文件model新建目录 */
			moveModelNewChild(res) {
				let that = this;
				let type = 2;
				let index = this.moveCurrentIndex;
				let id = this.moveCurrentId;
				this.$apiRequest.post("/attach/newlyBuildFile", {
					id: id,
					type: this.pageType,
				}).then(res => {
					let item = res.data.data;
					let arry = {
						index: index + 1
					};
					for (var i in arry) {
						item[i] = arry[i]
					}

					if (res.data.code == 200) {
						message.success('目录新建成功');
						that.getCatalogue();
						bus.$emit(type == 1 ? 'openRenameChild' : 'openRenameModelChild', {
							'item': item,
						})

					}

				}).catch(err => {
					baseFunc.checkState(err.data);
				});

			},
			/* 关闭移动文件model */
			closeMoveFileModel() {
				this.moveFileModelShow = false;
			},

			/* 移动文件 */
			moveFile() {
				let that = this;
				let index = this.selectItem.index;
				let id = this.selectItem.id;

				let pIndex = this.moveCurrentIndex;
				let pid = this.moveCurrentId;
				this.confirmLoading = true;
				if (this.topChange == 1) {
					that.$apiRequest.post("/attach/getMoveFile", {
						/* 文件移动 */
						id: id,
						pid: pid,
						type: this.pageType,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("文件移动成功");
							that.moveFileModelShow = false;
							that.confirmLoading = false;
							that.getCatalogue();
							that.getCatalogueData();
						}
					}).catch(err => {
						that.confirmLoading = false;
						baseFunc.checkState(err.data);
					});
				} else {
					that.$apiRequest.post("/attach/getDeleteFile", {
						/* 文件恢复 */
						id: id,
						meau_id: pid,
						type: this.pageType,
						del: 3,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("文件恢复成功");
							that.moveFileModelShow = false;
							that.confirmLoading = false;
							that.getRecycleData();
						}
					}).catch(err => {
						that.confirmLoading = false;
						baseFunc.checkState(err.data);


					});
				}


			},
			/* 统计 */
			census(e, item) {
				// this.statisticModelShow = true;
				// this.statisticInfo.id_key = item.id_key;
				operation(20, item);
			},
			/* 预览或设置 */
			previewAndSetQrcode(item, type) {
				this.picPreview = false;
				if (pageType == 1) {
					this.picPreview = true;
				} else if (pageType == 2) {
					this.mbmPlayerModelShow = true;
					this.selectItem = item;
				} else if (pageType == 3) {
					window.open(item.party_path);
				}
			},
			/* 复制 */
			copyQrcode(e, item) {
				operation(2, item);
			},
			/* 编辑 */
			editQrcode(e, item) {
				// window.open(item.edit_url);
				operation(3, item);
			},
			/* 下载二维码 */
			downloadQrcode(e, item) {
				// let url = item.download_url;
				// window.location.href = url;
				operation(14, item);
			},
			/* 打开文件移动model */
			openMoveFile() {
				this.moveFileModelShow = true;
				this.moveCurrentId = 0;
				this.moveCurrentIndex = 1;
				this.moveCurrentName = '全部活码';
			},
			/* 文件重命名 */
			fileRename(e, item) {
				this.renameInputShow = false;
				let value = e.target.value;
				let that = this;
				if (item.filename != value) {
					item.filename = value;

					this.$apiRequest.post("/attach/saveRenameFile", {
						id: item.qrcode_id,
						type: pageType,
						title: value,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("重命名成功");
							that.selectItem.title = value;
						}
					}).catch(err => {
						// that.getCatalogueData();
						baseFunc.checkState(err.data);
					});
				};

			},
			//新建活码
			newQrcode() {
				operation(9, {});
			},
			//打开文件重命名
			openFileRename(e, item) {
				this.selectItem = item;
				this.renameInputShow = true;

			},
			//Ant气泡框隐藏
			hideAntPopover() {
				this.antPopoverShow = false;
			},
			//Ant气泡框显示
			openAntPopover(e, item) {
				let arr = Object.keys(this.selectItem);
				let that = this;
				if (item.id == this.selectItem.id && item.index == this.selectItem.index && this.antPopoverShow == true) {
					this.hideAntPopover();
				} else {

					this.antPopoverShow = true;
					this.renameInputShow = false;
					this.selectItem = item;

					function emojiDisShow() {
						//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
						that.hideAntPopover();
						document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
					}
					document.body.addEventListener('click', emojiDisShow);
				}
			},

			//改变文件列表展示样式
			changeFileListStyle() {
				let that = this;
				if (this.fileListStyle == 1) {
					this.fileListStyle = 2;
				} else {
					this.fileListStyle = 1;
				}
			},
			//从page组件传递过来的当前page
			pageChange(currentpage) {

				this.currentPage = currentpage;
				this.loadingShow = true;

				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				}
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				this.indeterminate = false;
				this.checkAll = false;
			},
			// 打开关闭目录操作框
			openCloseHandle(e, item) {
				var that = this;
				this.handleId = item.id;
				this.handleIndex = item.index;
				this.selectItem = item;
				this.selectDocument = e;
				this.handleShow = true;

				function emojiDisShow() {
					//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
					that.handleShow = false;
					document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
				}
				document.body.addEventListener('click', emojiDisShow);

			},
			//改变选中文件
			changeFile(index, item, type) {

				this.previewAndSetQrcode(item, type)
				this.fileIndex = index;
				this.handleShow = false;
			},
			// 顶部切换
			changeTop(e) {
				this.topChange = e.currentTarget.dataset.index;
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				this.loadingShow = true;
				this.currentId = 0;
				this.currentIndex = 1;
				if (this.topChange == 1) {
					this.getCatalogue();
					this.getCatalogueData();
					this.menuData = this.videoData;
				} else {
					this.backData = [{
						id: 0,
						index: 1,
						type: 'catalogue',
						title: '回收站',
						openStatus: true,
						child: [],
					}]
					this.menuData = this.backData;
					this.getRecycleData();

				}
			},
			//获取回收站数据
			getRecycleData() {
				let that = this;
				this.$apiRequest.post("/attach/getRecycleData", {
					type: this.pageType,
					page: this.curretPage,
					size: this.pageSize,
					sort: this.updataTime,
					keywords: this.searchValue,
					check_power: typeof check_power != 'undefined' ? check_power : '',
				}).then(res => {
					if (res.data.code == 200) {
						that.contentList = res.data.data.data;
						this.loadingShow = false;
					}
				}).catch(err => {
					this.loadingShow = false;
					baseFunc.checkState(err.data);
				});
			},
			// 更新时间
			changeUpdataTime() {
				let that = this;
				this.currentPage = 1;
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				if (this.updataTime == 1) {
					this.updataTime = 2;
				} else {
					this.updataTime = 1;
				}
				this.loadingShow = true;
				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				}
			},
			//回车标题搜索
			enterSearch(e) {
				//Enter键的代码就是13
				if (e.keyCode == 13) {
					this.searchTitle();
				}
			},
			//标题搜索
			searchTitle() {
				this.currentPage = 1;
				this.loadingShow = true;
				this.fileSelectList = [];
				this.radiofileSelectList = '';
				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				}
			},
			//目录切换
			changeCurrentCatalogue(e) {
				let index = e.currentTarget.dataset.index;
				this.currentCatalogue = index;
				this.contentList = this.videoData[index].fileList;
			},
			//上传文件
			getVideo(e) {
				let files = event.target.files;
				let that = this;
				for (let i = 0; i < files.length; i++) {
					this.newUploadList.push({
						progress: 0
					});
					this.$apiRequest.uploadMediaToQiniu(this, files[i], progress => {
						/*进度条*/
						this.newUploadList[i].progress = progress.percent;
					}, complete => {}, {
						"meau_id": that.currentId,
						/*附件所在目录id*/
					}).then(res => {
						that.getCatalogueData();
						this.newUploadList = [];
					}).catch(err => {
						if (err.data.code != 200) {
							this.newUploadList = [];
							baseFunc.checkState(err.data);
						}
					});
				};

			},
		}

	}
</script>

<style>
	.mbm_player_model .ant-modal-body {
		padding: 24px;
	}

	.mbm_videoQrcode_item_right {
		width: 60px;
		height: 60px;
		min-width: 60px;
	}

	.mbm_videoQrcode_item_right>img {
		width: 100%;
		height: 100%;
	}
</style>
