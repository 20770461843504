<template>
	<div>
		<a-modal v-model:visible="visible" :confirmLoading="confirmLoading" width="1000px" title="H5转发设置" cancelText="取消" okText="确认" centered :destroyOnClose="true" :keyboard="false" :maskClosable="false"
			@cancel="cancel" @ok="ok">
			<div>
				<div class="forward-set-warp">
					<div class="forward-set-warp__l">
						<div class="forwardl-box">
							<div class="forwardl-box__item">
								<div class="forwardl-box__item__title"><label>标题</label></div>
								<div class="forwardl-box__item__content">
									<a-input :maxlength="20" v-model:value="forward.title" placeholder="请输入转发标题" />
								</div>
							</div>
							<div class="forwardl-box__item">
								<div class="forwardl-box__item__title"><label>描述</label></div>
								<div class="forwardl-box__item__content">
									<a-textarea :maxlength="50" v-model:value="forward.desc" placeholder="请输入转发描述" :rows="4" />
								</div>
							</div>
							<div class="forwardl-box__item">
								<div class="forwardl-box__item__title"><label>封面图</label></div>
								<div class="forwardl-box__item__content">
									<div class="forwardl-box__item__content__img">
										<div class="forwardl-box__item__content__img__reagain" @click="fileData.selectImg" v-if="fileData.status=='success'"><span>重新上传</span></div>
										<div class="forwardl-box__item__content__img__del" @click="fileData.del" v-if="fileData.status=='success'"><span>×</span></div>
										<div class="forwardl-box__item__content__img__reagain" v-if="fileData.status=='uploading'"><a-progress :percent="fileData.percent" status="active" :showInfo="false" /></div>
										<img class="forwardl-box__item__content__img__path" v-if="fileData.status=='success'" :src="fileData.path"/>
										<div class="forwardl-box__item__content__img__add" @click="fileData.selectImg" v-if="!fileData.status">
											<PlusOutlined :style="{fontSize:'20px'}" />
											<span>点击上传</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="forward-set-warp__r">
						<div class="forwardr-box">
							<text class="forwardr-box__lable">转发效果预览</text>
							<div class="forwardr-box__effect">
								<div class="forwardr-box__effect__title">{{forward.title}}</div>
								<div class="forwardr-box__effect__desc">
									<text class="forwardr-box__effect__desc__name">{{forward.desc}}</text>
									<img :src="fileData.path?fileData.path:'/img/link.png'" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="forward-tip">
					<InfoCircleOutlined />
					<span style="margin: 0px 2px 0 6px;">转发设置，需审核通过后才会生效！</span>
				</div>
				<div class="forward-tip" v-if="vipInfo.data.vip_status==0">
					<InfoCircleOutlined />
					<span style="margin: 0px 2px 0 6px;">{{vipInfo.data.formard_message}}</span>
					<a class="forward-tip__a" :href="upgradeUrl" target="_blank">去升级</a>
				</div>
				<input ref="addForwardImageRef" type="file" accept="image/jpg,image/jpeg,image/png" @change="fileData.forwardImageChange($event)" v-show="false" />
			</div>
		</a-modal>
	</div>
</template>


<script>
	import { PlusOutlined,ExclamationCircleOutlined,InfoCircleOutlined } from '@ant-design/icons-vue';
	import { message,Modal } from 'ant-design-vue';
	import { createVNode } from 'vue';
	import baseFunc from '/src/config/base_func.js'
	import config from '/src/config/config.js'
	
	export default {
			name:'ForwardSet',
			components: {
				PlusOutlined,ExclamationCircleOutlined,InfoCircleOutlined
			},
			props:{
				show:Boolean,
				info:String||Object,
				id_key:String,
				type:Number
			},
			created(){
				this.vipInfo.getUserDefultSet();
			},
			mounted(){
			},
			data(){
				return {
					visible:false,
					confirmLoading:false,//确认按钮状态
					upgradeUrl:config.upgradeUrl,
					fileData:{
						status:'',//uploading:上传中 success:上传成功
						percent:0,
						path:'',
						file:'',
						del:()=>{
							const that = this
							Modal.confirm({
								title: () => '删除提示',
								icon: () => createVNode(ExclamationCircleOutlined),
								content: () => createVNode('div', { style: 'color:red;' }, '确认删除封面图吗，删除后不可恢复?'),
								centered: () => true,
								okType: 'danger',
								cancelText: () => '取消',
								okText: () => '删除',
								onOk() {
									that.fileData.status='';
									that.fileData.percent=0;
									that.fileData.path='';
									that.fileData.file='';
								},
								onCancel() {},
							});
						},
						selectImg:()=>{
							this.$refs.addForwardImageRef.dispatchEvent(new MouseEvent('click'));
						},
						forwardImageChange:(e)=>{
							let file = e.target.files[0];
							this.fileData.status='success';
							this.fileData.status='uploading';
							this.$apiRequest.uploadFileToAliOss(this, file, progress => {
								/*进度条*/
								this.fileData.percent = parseInt(progress.percent.toFixed(0))
							}, complete => {}, {
								"upload_type": 'img',
							}).then(res => {
								this.fileData.status='success';
								this.fileData.file = res;
								this.fileData.path = baseFunc.getAliOssAuthKey(res.path)
							}).catch(err => {
								this.fileData.del();
								message.error(err.message)
							});
							this.$refs.addForwardImageRef.value = null
						}
					},
					forward:{
						id:0,
						title:"",
						desc:"",
					},
					vipInfo:{
						data:{
							mode_message:"",
							vip_status:-1,
						},
						getUserDefultSet:()=>{
							const that = this;
							return new Promise(function(resolve, reject) {
								that.$apiRequest.post("/qrcode/create/getUserDefultSet", {
									
								}).then((res) => {
									that.vipInfo.data = res.data.data
									resolve(res.data.data)
								}).catch((err) => {
									reject()
								});
							})
						},
						checkUserVip:()=>{
							const that = this;
							return new Promise(function(resolve, reject) {
								if(that.vipInfo.data.vip_status>0){
									resolve()
								}else{
									that.vipInfo.getUserDefultSet().then((ret) => {
										if(ret.vip_status>0){
											resolve()
										}else{
											baseFunc.checkState({
												code:11001,
												message:ret.formard_message
											}).then(() => {}).catch(() => {})
											reject()
										}
									}).catch((err) => {
										reject()
									})
								}
							})
						},
					}
				};
			},
			methods:{
				init(){
					this.visible = this.show;
				},
				ok(){
					if(this.confirmLoading)return;
					if(this.fileData.status=='uploading'){
						message.warning('封面图上传中，请稍后…');
						return false;
					}
					this.confirmLoading=true;
					const that = this;
					this.vipInfo.checkUserVip().then(() => {
						let content={
							title:that.forward.title,
							desc:that.forward.desc,
							image:that.fileData.file,
						}
						that.$apiRequest.post("/qrcode/create/forwardSet", {
							id:that.forward.id,
							type:that.type,
							id_key:that.id_key,
							content:JSON.stringify(content)
						}).then((res) => {
							message.success(res.data.message);
							that.clearInitData()
							that.confirmLoading=false;
							that.$emit('ok',{show:false,data:res.data.data})
						}).catch((err) => {
							that.confirmLoading=false;
							if(err.data)baseFunc.checkState(err.data).then(() => {}).catch(() => {});
						});
					}).catch(() => {
						this.confirmLoading=false;
					})
				},
				cancel(){
					this.clearInitData()
					this.$emit('cancel',{show:false})
				},
				clearInitData(){
					this.fileData.status='';
					this.fileData.percent=0;
					this.fileData.path='';
					this.fileData.file='';
					this.confirmLoading=false;
					this.forward={
						id:0,
						title:"",
						desc:"",
					}
				}
			},
			watch:{
				info:{
				    handler(newVal,oldVal){
						if(newVal){
							let oldForward=JSON.parse(JSON.stringify(newVal))
							this.forward={
								id:oldForward.id,
								title:oldForward.content.title,
								desc:oldForward.content.desc,
							};
							if(oldForward.content.image){
								this.fileData.status='success';
								this.fileData.percent=100;
								this.fileData.path=baseFunc.getAliOssAuthKey(oldForward.content.image.path);
								this.fileData.file=oldForward.content.image;
							}
						}else{
							this.forward={
								id:0,
								title:"",
								desc:"",
							};
						}
				    },
				    deep:true// 深度监听父组件传过来对象变化
				},
				show:{
				    handler(newVal,oldVal){
						if(newVal!=oldVal){
							this.init();
						}
				    },
				    deep:true// 深度监听父组件传过来对象变化
				}
			}
		}
		
</script>

<style scoped lang="less">
	.forward-set-warp{
		width: 100%;
		display: flex;
		padding:10px 0;
		&__l{
			flex: 1;
			text-align: center;
			.forwardl-box{
				width: 100%;
				position: relative;
				&__item{
					display: flex;
					padding: 0 44px;
					min-height: 32px;
					margin-bottom: 24px;
					&__title{
						width: 60px;
						min-width: 60px;
						text-align: right;
						margin-right: 10px;
						label{
							font-size: 14px;
							font-weight: 500;
							color: #595959;
							line-height: 32px;
						}
					}
					&__content{
						flex: 1;
						&__img{
							width: 105px;
							height: 105px;
							position: relative;
							border-radius: 4px;
							overflow: hidden;
							background-color: #FAFAFA;
							&__add{
								text-align: center;
								height: 100%;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								cursor: pointer;
								color:#666666;
								&:hover{
									color: #09bd71;
								}
								span{
									margin-top: 10px;
									font-size: 13px;
								}
							}
							&__reagain{
								position: absolute;
								z-index: 1;
								background-color: rgba(51,51,51,.4);
								display: flex;
								align-items: center;
								justify-content: center;
								width: 100%;
								height: 100%;
								cursor: pointer;
								color: #ffffff;
								font-size: 13px;
								padding: 0 12px;
							}
							&__del{
								position: absolute;
								z-index: 3;
								top: -14px;
								right: -14px;
								background-color: #3F4447;
								width: 28px;
								height: 28px;
								border-radius: 14px;
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;
								span{
									position: relative;
									top: 5px;
									right: 6px;
									color: #ffffff;
									font-size: 12px;
									line-height: 1;
									font-weight: normal;
								}
								&:hover{
									background-color: #ff0000;
								}
							}
							&__path{
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
			}
		}
		&__r{
			flex: 1;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			.forwardr-box{
				width: 400px;
				padding: 42px 24px;
				background-color: #F3F3F3;
				border-radius: 2px;
				text-align: left;
				&__lable{
					font-size: 14px;
					font-weight: 500;
					color: #595959;
					padding-left: 20px;
				}
				&__effect{
					margin-top: 40px;
					width: 100%;
					background-color: #ffffff;
					border-radius: 10px;
					padding: 20px;
					&__title{
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
						margin-bottom: 10px;
						height: 28px;
					}
					&__desc{
						width: 100%;
						display: flex;
						justify-content: space-between;
						&__name{
							flex: 1;
							white-space: pre-line;
							display: -webkit-box;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							color: #999999;
							font-size: 14px;
							line-height: 22px;
							max-height: 66px;
						}
						img{
							margin-left: 24px;
							min-width: 75px;
							width: 75px;
							height: 75px;
							border-radius: 4px;
						}
					}
				}
			}
		}
	}
	.forward-tip{
		width: 100%;
		color:#FF8A00;
		font-size:14px;
		display: flex;
		align-items: center;
		padding-left: 112px;
		margin-bottom: 10px;
		&__a{
			display: flex;
			border-radius: 4px;
			background-color: #09bd71;
			color: #ffffff;
			padding: 0 8px;
			margin-left: 5px;
			font-size: 12px;
			height: 24px;
			line-height: 24px;
			&:hover{background-color:#2ac982;}
		}
	}
</style>