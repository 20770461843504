<template>
	<div class="mbm_wenjian_item" v-for="(item,index) in remove_floader_data" :key="item.id">

		<div class="mbm_wenjian_item_parent"
			:class="{'mbm_wenjian_item_active':((currentId==item.id)&(currentIndex==item.index))}" :data-id="item.id"
			:data-index="item.index" @click.stop="removeSelectChlid(item.child?item.child.length:0,item,$event)"
			:style="{'color': checkIdIndex(item.id,item.index)?'#e7e7e7':'#333333'}">

			<span v-show="item.child && item.child.length>0" class="mbm-iconfont mbm-daosanjiao "
				:class="{'mbm_childOpen':item.openStatus,'mbm_childClose':item.openStatus==false}" :data-id="item.id"
				:data-index="item.index"
				@click.stop="OpenCloseChlid(item.child?item.child.length:0,item,$event)"></span>
			<div v-show="!((currentId==item.id)&(currentIndex==item.index)&renameShow)"
				style="position: relative;width: 100%;height: 100%;"
				:style="{'cursor': checkIdIndex(item.id,item.index,noSelectList)?'not-allowed':'pointer',}">
				<div
					style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: space-between;">

					<div class="mbm_wenjian_item_left">
						<span class="mbm-iconfont"
							:class="{'mbm-muludakai':item.openStatus,'mbm-muluguanbi':item.openStatus==false,}"></span>
						{{item.title}}
					</div>

					<span v-if="(currentId==item.id)&(currentIndex==item.index)" class="mbm-iconfont mbm-check"
						style="font-size: 12px;color: #167FE8;"></span>

				</div>
			</div>

			<div v-show="(currentId==item.id)&(currentIndex==item.index)&renameShow" class="mbm_reName_input_box">
				<input class="mbm_reName_input" style="max-width: 200px;" ref="renameInput" :value="item.title"
					@blur="renameChild($event,item)" v-focus />
				<div class="mbm_reName_input_btn_box">
					<div class="mbm_reName_input_btn">
						<span v-if="(currentId==item.id)&(currentIndex==item.index)" class="mbm-iconfont mbm-check"
							style="font-size: 12px;color: #20bf64;"></span>
					</div>
					<div class="mbm_reName_input_btn" @mousedown="deleteCatalogue($event,item,2)">
						<span v-if="(currentId==item.id)&(currentIndex==item.index)" class="mbm-iconfont mbm-guanbi1"
							style="font-size: 12px;color: #e64340;"></span>
					</div>
				</div>

			</div>


		</div>
		<transition v-if="item.child" name="panel-fade" @before-enter="beforeEnter" @enter="enter($event,item.child.length)" @after-enter="afterEnter"
			@before-leave="beforeLeave($event,item.child.length)" @leave="leave" @after-leave="afterLeave">
			<div v-show="item.openStatus" style="padding-left: 20px;" class="mbm_childBox" transition="panel-fade">
				<removeFloader :maxLevel="maxLevel" :remove_floader_data="item.child" :currentId="parseInt(currentId)"
					:currentIndex="parseInt(currentIndex)" :noSelectList="noSelectList" :parentItem="item">
				</removeFloader>
			</div>
		</transition>

	</div>

</template>

<script>
	import removeFloader from '/src/components/removeFloader.vue';
	import bus from '/src/assets/js/bus.js';

	export default {
		name: 'removeFloader',
		components: {
			removeFloader,

		},
		data() {
			return {
				childShow: false, //文件夹的子目录显隐
				handleShow: false, //三个点操作box显隐
				renameShow: false, //重命名input显隐
				deleteModelShow: false, //删除弹窗的显隐
				deleteName: '', //删除文件夹名字的显隐
				removeData: this.remove_floader_data,
				childList: [],
				selectItem: {},
				elTransition: '0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out',
			}
		},
		props: {
			maxLevel:{
				type:Number,
				default:5,
			},
			remove_floader_data: Array,
			currentId: Number,
			currentIndex: Number,

			noSelectList: Object,
			parentItem: Object,
		},
		watch: {
			noSelectList: function(indexVal, oldVal) {
				// console.log(indexVal, oldVal)
			}
		},
		created() {

			bus.$on('openRenameModelChild', e => {
				let item = e.item;
				this.selectItem = item;
				// this.removeData = e.allData;
				if (item.pid == this.parentItem.id) {
					// console.log(item.pid, this.parentItem.id)
					this.openRenameChild(e, item);
				}
			});

		},
		methods: {
			//transition展开收起动画-start
			beforeEnter(el) {
				el.style.transition = this.elTransition
				if (!el.dataset) el.dataset = {}
			
				el.dataset.oldPaddingTop = el.style.paddingTop
				el.dataset.oldPaddingBottom = el.style.paddingBottom
			
				el.style.height = 0
				el.style.paddingTop = 0
				el.style.paddingBottom = 0
			},
			enter(el, length) {
				el.dataset.oldOverflow = el.style.overflow
				if (el.scrollHeight !== 0) {
					el.style.height = length*36 + 'px'
					el.style.paddingTop = el.dataset.oldPaddingTop
					el.style.paddingBottom = el.dataset.oldPaddingBottom
				} else {
					el.style.height = ''
					el.style.paddingTop = el.dataset.oldPaddingTop
					el.style.paddingBottom = el.dataset.oldPaddingBottom
				}
			
				el.style.overflow = 'hidden'
			},
			afterEnter(el) {
				el.style.transition = ''
				el.style.height = ''
				el.style.overflow = el.dataset.oldOverflow
			},
			beforeLeave(el, length) {
				if (!el.dataset) el.dataset = {}
				el.dataset.oldPaddingTop = el.style.paddingTop
				el.dataset.oldPaddingBottom = el.style.paddingBottom
				el.dataset.oldOverflow = el.style.overflow
				el.style.height = length*36 + 'px'
				el.style.overflow = 'hidden'
			},
			leave(el, done) {
			
				if (el.scrollHeight !== 0) {
					el.style.transition = this.elTransition;
					el.style.height = 0;
					el.style.paddingTop = 0;
					el.style.paddingBottom = 0;
				}
			},
			afterLeave(el) {
				el.style.transition = ''
				el.style.height = ''
				el.style.overflow = el.dataset.oldOverflow
				el.style.paddingTop = el.dataset.oldPaddingTop
				el.style.paddingBottom = el.dataset.oldPaddingBottom
			},
			//transition展开收起动画-end

			// 取消数据数据双向绑定
			formatData(groupList) {
				if (groupList !== undefined && groupList !== null) {
					return JSON.parse(JSON.stringify(groupList))
				} else {
					return []
				}
			},
			//检查当前文件夹是否在禁选列表
			checkIdIndex(id, index) {
				let result = false;
				if (this.noSelectList) {
					for (let i = 0; i < this.noSelectList.length; i++) {
						let item = this.noSelectList[i];

						if (item.id == id && item.index == index) {
							result = true
						}
					}
				}
				return result;
			},
			//目录切换
			removeCatalogueChange(index, id, item) {
				if (this.currentId != id) {
					bus.$emit('removeCatalogueChange', {
						'index': index,
						'id': id,
						'name': item.title,
					});
				}

			},
			//打开或关闭目录
			OpenCloseChlid(length, item, e) {
				let index = e.currentTarget.dataset.index;
				let id = e.currentTarget.dataset.id;
				if (length && length != 0) {
					if (item.openStatus) {
						item.openStatus = false;
					} else {
						item.openStatus = true;
					}
				}
			},
			//选中切换
			removeSelectChlid(length, item, e) {
				try {
					// console.log(this.handleIndex + ':' + this.handleId)
					// console.log(item.index + ':' + item.id)
					if (!this.checkIdIndex(item.id, item.index)) {

						let index = e.currentTarget.dataset.index;
						let id = e.currentTarget.dataset.id;
						item.openStatus = true;
						this.removeCatalogueChange(index, id, item); //选中目录改变
					}

				} catch (e) {
					//TODO handle the exception
				}
			},
			// 打开关闭目录操作框
			openCloseHandle() {
				var that = this;
				if (this.handleShow) {
					this.handleShow = false;

				} else {
					this.handleShow = true;

					function emojiDisShow() {
						//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
						that.handleShow = false;
						document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
					}
					document.body.addEventListener('click', emojiDisShow);
				}
			},
			//新建子目录
			newChild(e) {
				let index = e.currentTarget.dataset.index;
				let id = e.currentTarget.dataset.id;

				bus.$emit('newChild', {
					'id': id,
					'index': index,

				})
			},
			//打开重命名
			openRenameChild(e, item) {
				// console.log('重命名')
				let that = this;
				let index = item.index;
				let id = item.id;
				this.removeCatalogueChange(index, id, item); //选中目录改变
				this.handleShow = false;
				this.renameShow = true;
			},
			//重命名
			renameChild(e, item) {
				this.renameShow = false;
				let value = e.target.value;
				let id = this.currentId;
				let index = this.currentIndex;
				if (item.title != value) {
					item.title = value;
					bus.$emit('rename', {
						'id': id,
						'index': index,
						'value': value,
					})
				}

			},
			//删除刚才创建的文件夹
			deleteCatalogue(e, item, type) {
				e.preventDefault()
				this.removeCatalogueChange(this.parentItem.index, this.parentItem.id, this.parentItem);
				bus.$emit('deleteCatalogue', {
					'id': item.id,
					"type": type,
				});
				item = null;
			}

		}
	}
</script>

<style>
	.mbm_reName_input_box {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.mbm_reName_input_btn_box {
		position: absolute;
		left: 205px;
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.mbm_reName_input_btn {
		width: 24px;
		height: 24px;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mbm_reName_input_btn:hover {
		background: #ebedf0;

	}


	.mbm_childBox {
		margin-top: 0;
		margin-bottom: 0;
		overflow: hidden;
		transition: all ease 0.5s;
	}
</style>
