<template>

	<div v-show="loadingShow" class="loading" :class="defaultClass">

		<div v-show="type==1" class="loading_item">
			<div class="mock">{{title}}</div>
			<div class="loader-inner ball-pulse">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		<!-- loading-A -->
		<div v-show="type==2" class="loading_item">

			<div class="wrap color-A">
				<p style="text-align: center;color: #09bd71;margin-top: 0;">{{title}}</p>
				<div class="k-line-box">
					<div class="k-line k-lineA-1"></div>
					<div class="k-line k-lineA-2"></div>
					<div class="k-line k-lineA-3"></div>
					<div class="k-line k-lineA-4"></div>
					<div class="k-line k-lineA-5"></div>
				</div>
			</div>
		</div>
		<!-- loading-B -->
		<div v-show="type==3" class="loading_item">
			<div style="color: #09bd71;margin-bottom: 10px;">{{title}}</div>
			<div class="loading_B"></div>
		</div>
		<div v-show="type==4" class="loading_item">
			<a-spin size="large" />
		</div>
	</div>

</template>

<script>
	export default {
		name: 'loading',
		props: {
			title: String,
			type: Number,
			loadingShow: Boolean,
			defaultClass:String,
		},
		methods: {

		}
	}
</script>

<style>
	.loading .ant-spin-dot-item{
		background-color: #09bd71;
	}
	
	.loading {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 300;
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mbm_loading {
		position: unset;
		height: calc(100% - 72px);
	}
	.loading .mock {
		height: auto;
		font-size: 16px;
		/*px*/
		color: #09bd71;
		text-align: center;
		line-height: 1.5;
	}

	.ball-pulse {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loading .ball-pulse div {
		background-color: #09bd71;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		margin: 2px;
		animation-fill-mode: both;
		display: inline-block;
	}

	.loading .ball-pulse div:nth-child(1) {
		animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
	}

	.loading .ball-pulse div:nth-child(2) {
		animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
	}

	.loading .ball-pulse div:nth-child(3) {
		animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
	}


	@-webkit-keyframes scale {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}

		45% {
			-webkit-transform: scale(0.1);
			transform: scale(0.1);
			opacity: 0.7;
		}

		80% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}

	@keyframes scale {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}

		45% {
			-webkit-transform: scale(0.1);
			transform: scale(0.1);
			opacity: 0.7;
		}

		80% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}



	/* loading-A */
	.k-line-box {
		height: 40px;
		display: flex;
		align-items: flex-end;
	}

	.k-line {

		width: 15px;
		height: 15px;
		border-radius: 15px;
		margin-left: 10px;
		margin-top: 20px;
		background: #09bd71;
	}

	.k-line:first-child {
		margin-left: 0px;
	}

	@keyframes k-loadingA {
		50% {
			height: 40px;
		}

		100% {
			height: 15px;
		}
	}

	.k-lineA-1 {
		animation: k-loadingA 1.5s 0s infinite;
	}

	.k-lineA-2 {
		animation: k-loadingA 1.5s 0.3s infinite;
	}

	.k-lineA-3 {
		animation: k-loadingA 1.5s 0.6s infinite;
	}

	.k-lineA-4 {
		animation: k-loadingA 1.5s 0.9s infinite;
	}

	.k-lineA-5 {
		animation: k-loadingA 1.5s 1.2s infinite;
	}

	/* loading-B */
	.loading_B {
		width: 10px;
		height: 10px;
		margin: 0 auto;
		padding: 10px;
		border: 7px dashed #09bd71;
		border-radius: 50%;
		animation: k-loadingB 2s linear infinite;
	}

	@keyframes k-loadingB {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>
